import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import { EmbedVideo, InlineVideo, YoutubeVideo } from "./../Shared"
import CalendarSVG from "./../../graphics/howto/calendar.svg"
import DimensionsSVG from "./../../graphics/howto/dimensions.svg"
import ScissorsSVG from "./../../graphics/howto/scissors.svg"
import SnowSVG from "./../../graphics/howto/snow.svg"
import SunSVG from "./../../graphics/howto/sun.svg"
import TileSVG from "./../../graphics/howto/tile.svg"
import Title from "../Title"
import VerticalTitle from "../VerticalTitle"

const Wrapper = styled.div`
    ${props =>
        props.color == "light" &&
        `
        background-color: ${props.theme.colors.darkMedium};
    `}

    ${props => props.theme.above.desktop`
        padding: 12rem 0 0 0;
    `}
`

const Header = styled.div`
    span {
        display: block;

        margin-bottom: 1rem;
    }

    ${props => props.theme.below.desktop`
        padding: 6rem 0 0 0;

        span {
            font-size: 1rem;
            line-height: 1rem;
            letter-spacing: 0.15rem;
            
            text-transform: uppercase;
        }

        h3 {
            font-size: 3.5rem;
            line-height: 3.5rem;
        }
    `}

    ${props => props.theme.above.desktop`
        text-align: center;
    `}
`

const StyledTitle = styled(Title)`
    margin-bottom: 0;

    .subtitle {
        padding: 0;
    }

    .line {
        display: none;
    }
`

const List = styled.ul`
    width: 100%;
`

const Item = styled.li`
    width: 100%;

    margin: 0 auto;

    &:nth-of-type(2) {
        .inner {
            flex-direction: row-reverse;
        }
    }

    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    ${props => props.theme.below.desktop`
        &:first-of-type {
            padding: 0;
        }

        &:last-of-type {            
            padding-bottom: 6rem;

            border-bottom: none;
        }
    `}

    ${props => props.theme.above.desktop`
        padding: 12rem 0;

        &:nth-of-type(2) {
            .content {
                padding-right: 0;
                padding-left: 10rem;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }
    `}
`

const Inner = styled.div`
    width: 100%;
    /* max-width: ${props => props.theme.breakpoints.extraWide}px; */

    margin: 0 auto;
    padding: 3rem;

    ${props => props.theme.above.desktop`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        /* padding: 0 6rem; */
    `}
`

const Content = styled(motion.div)`
    width: 100%;

    ${props => props.theme.above.desktop`
        width: 50%;
        max-width: 60rem;

        padding-right: 10rem;
    `}
`

const ContentTitle = styled.h4`
    margin-bottom: 1rem;

    overflow: hidden;
`

const TitleLabel = styled(motion.span)`
    display: block;

    font-family: ${props => props.theme.fontFamily.minionSemibold};
    font-size: 2.4rem;
    line-height: 2.4rem;

    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        font-size: 3rem;
        line-height: 3rem;
    `}
`

const ContentSubtitle = styled(motion.h5)`
    margin-bottom: 0.5rem;

    span {
        display: inline-block;
        vertical-align: middle;

        font-family: ${props => props.theme.fontFamily.circularMedium};
        letter-spacing: 0;

        color: ${props => props.theme.colors.white};
    }
`

const ContentDescription = styled(motion.div)`
    margin-bottom: 3rem;

    &:last-of-type {
        margin-bottom: 4rem;
    }

    ${props => props.theme.above.desktop`
        &:last-of-type {
            margin-bottom: 0;
        }
    `}
`

const VideoWrapper = styled(motion.div)`
    position: relative;

    width: 100%;

    margin-top: 2rem;

    ${props => props.theme.above.desktop`
        width: 50%;

        margin-top: 0;
    `}
`

const VideoStyles = css`
    width: 100%;
`

const StyledVideo = styled.div`
    ${VideoStyles}
`

const StyledFallbackImage = styled(Img)`
    ${VideoStyles}
`

const IconStyles = css`
    display: inline-block;
    vertical-align: middle;

    width: 2.5rem;

    margin-right: 1rem;
`

const StyledCalendar = styled(CalendarSVG)`
    ${IconStyles}
`

const StyledDimensions = styled(DimensionsSVG)`
    ${IconStyles}
`

const StyledScissors = styled(ScissorsSVG)`
    ${IconStyles}
`

const StyledSnow = styled(SnowSVG)`
    ${IconStyles}
`

const StyledSun = styled(SunSVG)`
    ${IconStyles}
`

const StyledTile = styled(TileSVG)`
    ${IconStyles}
`

const Video = ({ data: { embed_video, inline_video } }) => {
    return (
        <StyledVideo>
            {embed_video && <EmbedVideo data={embed_video} />}
            {inline_video[0].mp4.target != "" && (
                <InlineVideo
                    data={inline_video}
                    placeholder={inline_video[0].placeholder}
                />
            )}
        </StyledVideo>
    )
}

const listVariants = {
    before: {},
    after: {
        transition: {
            staggerChildren: 0.05,
        },
    },
}

const titleVariants = {
    before: {
        y: 100,
        skewY: 3,
    },
    after: {
        y: 0,
        skewY: 0,
    },
}

const subtitleVariants = {
    before: {
        y: -50,
        skewY: 3,
        opacity: 0,
    },
    after: {
        y: 0,
        skewY: 0,
        opacity: 1,
    },
}

const descriptionVariants = {
    before: {
        y: -50,
        opacity: 0,
    },
    after: {
        y: 0,
        opacity: 1,
    },
}

const videoVariants = {
    before: {
        y: 50,
        opacity: 0,
    },
    after: {
        y: 0,
        opacity: 1,
    },
}

const transition = {
    duration: 0.55,
    damping: 100,
    stiffness: 100,
    ease: "circOut",
}

const HowTo = ({ pruning, planting, growing, general, color }) => {
    const [plantingRef, plantingInView] = useInView({
        threshold: 0.15,
        triggerOnce: true,
    })

    const [growingRef, growingInView] = useInView({
        threshold: 0.15,
        triggerOnce: true,
    })

    const [pruningRef, pruningInView] = useInView({
        threshold: 0.15,
        triggerOnce: true,
    })

    return (
        <Wrapper color={color}>
            <Header>
                <StyledTitle
                    subtitle={general.product.text}
                    title={general.component.text}
                    alignment="center"
                />
            </Header>
            <List>
                <Item ref={growingRef} className="growing">
                    <Inner className="inner">
                        <Content
                            className="content"
                            initial="before"
                            animate={growingInView ? "after" : "before"}
                            variants={listVariants}
                        >
                            <ContentTitle>
                                <TitleLabel
                                    initial="before"
                                    variants={titleVariants}
                                    transition={transition}
                                >
                                    {general.growing[0].growing_title.text}
                                </TitleLabel>
                            </ContentTitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        general.growing[0].growing_description
                                            .html,
                                }}
                            />
                            <ContentSubtitle
                                initial="before"
                                variants={subtitleVariants}
                                transition={transition}
                            >
                                <StyledSnow />
                                <span>{growing[0].minimal_title}</span>
                            </ContentSubtitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html: growing[0].minimal_description.html,
                                }}
                            />
                            <ContentSubtitle
                                initial="before"
                                variants={subtitleVariants}
                                transition={transition}
                            >
                                <StyledSun />
                                <span>{growing[0].optimal_title}</span>
                            </ContentSubtitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html: growing[0].optimal_description.html,
                                }}
                            />
                        </Content>
                        <VideoWrapper
                            animate={growingInView ? "after" : "before"}
                            initial="before"
                            variants={videoVariants}
                            transition={transition}
                        >
                            {general.growing[0].youtube_id != null ? (
                                <YoutubeVideo
                                    data={{
                                        youtube_id:
                                            general.growing[0].youtube_id,
                                    }}
                                    type="how-to"
                                />
                            ) : (
                                <div style={{ position: "relative" }}>
                                    <StyledFallbackImage
                                        fluid={
                                            general.growing[0].fallback_image
                                                .fluid
                                        }
                                        alt={
                                            general.growing[0].fallback_image
                                                .alt
                                        }
                                    />
                                    <VerticalTitle
                                        title={
                                            general.growing[0].fallback_image
                                                .alt
                                        }
                                    />
                                </div>
                            )}
                        </VideoWrapper>
                    </Inner>
                </Item>
                <Item ref={plantingRef} className="planting">
                    <Inner className="inner">
                        <Content
                            className="content"
                            animate={plantingInView ? "after" : "before"}
                            initial="before"
                            variants={listVariants}
                        >
                            <ContentTitle>
                                <TitleLabel
                                    initial="before"
                                    variants={titleVariants}
                                    transition={transition}
                                >
                                    {general.planting[0].planting_title.text}
                                </TitleLabel>
                            </ContentTitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        general.planting[0].planting_description
                                            .html,
                                }}
                            />
                            <ContentSubtitle
                                initial="before"
                                variants={subtitleVariants}
                                transition={transition}
                            >
                                <StyledDimensions />
                                <span>{planting[0].size_title}</span>
                            </ContentSubtitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html: planting[0].size_description.html,
                                }}
                            />
                            <ContentSubtitle
                                initial="before"
                                variants={subtitleVariants}
                                transition={transition}
                            >
                                <StyledTile />
                                <span>{planting[0].dimensions_title}</span>
                            </ContentSubtitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        planting[0].dimensions_description.html,
                                }}
                            />
                        </Content>
                        <VideoWrapper
                            animate={plantingInView ? "after" : "before"}
                            initial="before"
                            variants={videoVariants}
                            transition={transition}
                        >
                            {general.planting[0].youtube_id != null ? (
                                <YoutubeVideo
                                    data={{
                                        youtube_id:
                                            general.planting[0].youtube_id,
                                    }}
                                    type="how-to"
                                />
                            ) : (
                                <StyledFallbackImage
                                    fluid={
                                        general.planting[0].fallback_image.fluid
                                    }
                                    alt={general.planting[0].fallback_image.alt}
                                />
                            )}
                        </VideoWrapper>
                    </Inner>
                </Item>
                <Item ref={pruningRef} className="pruning">
                    <Inner className="inner">
                        <Content
                            className="content"
                            initial="before"
                            animate={pruningInView ? "after" : "before"}
                            variants={listVariants}
                        >
                            <ContentTitle>
                                <TitleLabel
                                    initial="before"
                                    variants={titleVariants}
                                    transition={transition}
                                >
                                    {general.pruning[0].pruning_title.text}
                                </TitleLabel>
                            </ContentTitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        general.pruning[0].pruning_description
                                            .html,
                                }}
                            />
                            <ContentSubtitle
                                initial="before"
                                variants={subtitleVariants}
                                transition={transition}
                            >
                                <StyledScissors />
                                <span>{pruning[0].prune_title}</span>
                            </ContentSubtitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html: pruning[0].prune_description.html,
                                }}
                            />
                            <ContentSubtitle
                                initial="before"
                                variants={subtitleVariants}
                                transition={transition}
                            >
                                <StyledCalendar />
                                <span>{pruning[0].flower_title}</span>
                            </ContentSubtitle>
                            <ContentDescription
                                initial="before"
                                variants={descriptionVariants}
                                transition={transition}
                                dangerouslySetInnerHTML={{
                                    __html: pruning[0].flower_description.html,
                                }}
                            />
                        </Content>
                        <VideoWrapper
                            animate={pruningInView ? "after" : "before"}
                            initial="before"
                            variants={videoVariants}
                            transition={transition}
                        >
                            {general.pruning[0].youtube_id != null ? (
                                <YoutubeVideo
                                    data={{
                                        youtube_id:
                                            general.pruning[0].youtube_id,
                                    }}
                                    type="how-to"
                                />
                            ) : (
                                <StyledFallbackImage
                                    fluid={
                                        general.pruning[0].fallback_image.fluid
                                    }
                                    alt={general.pruning[0].fallback_image.alt}
                                />
                            )}
                        </VideoWrapper>
                    </Inner>
                </Item>
            </List>
        </Wrapper>
    )
}

export default HowTo
