import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

import Title from "./../../components/Title"
import { YoutubeVideo } from "../Shared"

const Wrapper = styled.section`
    position: relative;

    background-color: ${props => props.theme.colors.darkLight};
`

const Inner = styled.div`
    width: 100%;

    padding: 6rem 0 0 0;

    text-align: center;

    ${props => props.theme.above.desktop`
        max-width: 75rem;

        margin: 0 auto;
        padding: 6rem 0 8rem 0;
    `}
`

const StyledTitle = styled(Title)`
    margin-bottom: 6rem;

    text-align: center;

    .subtitle {
        padding: 0;
    }

    .line {
        display: none;
    }

    ${props => props.theme.above.desktop`
        margin-bottom: 5rem;
    `}
`

const StyledYoutubeVideo = styled(YoutubeVideo)`
    margin: 0;
`

const VideoBanner = ({ className, lang, videoId, title, subtitle }) => {
    return (
        <Wrapper className={className}>
            <Inner>
                <StyledTitle
                    subtitle={subtitle}
                    title={title}
                    alignment="center"
                />
                <StyledYoutubeVideo
                    data={{
                        youtube_id: videoId,
                    }}
                    type="how-to"
                />
            </Inner>
        </Wrapper>
    )
}

export default VideoBanner
