import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Seo from "./../components/Layout/Seo"
import MobileCarousel from "./../components/Product/MobileCarousel"
import DesktopGallery from "./../components/Product/DesktopGallery"
import ProductInformation from "./../components/Product/ProductInformation"
import ProductLegal from "./../components/Product/ProductLegal"
import HowTo from "./../components/Product/HowTo"
import { Quotes, Related } from "./../components/Shared"
import Banner from "./../components/Product/Banner"
import VideoBanner from "./../components/Product/VideoBanner"

const DesktopWrapper = styled.div`
    position: relative;

    font-size: 0;
`

const ProductItem = ({
    pageContext: { lang, langSlug },
    data: {
        collection,
        product: {
            data: {
                seo_title,
                seo_description,
                seo_keywords,
                seo_image,
                thumbnail,
                product_title,
                product_description,
                product_legal,
                product_tag,
                product_size,
                product_dimensions,
                product_family,
                product_quotes,
                product_awards_group,
                youtube_id,
                related_items,
                planting,
                pruning,
                growing,
                body,
            },
        },
    },
}) => {
    const gallery = body[0].items
    const usps = collection.data.usp_items

    const howtoData = {
        product: product_title,
        component: collection.data.howto_title,
        pruning: collection.data.pruning,
        planting: collection.data.planting,
        growing: collection.data.growing,
    }

    const bannerData = {
        subtitle: collection.data.banner_subtitle,
        title: collection.data.banner_title,
        description: collection.data.banner_description,
        buttonLabel: collection.data.banner_button_label,
        buttonLink: collection.data.banner_button_link,
    }

    const videoData = {
        subtitle: collection.data.video_banner_subtitle,
        title: collection.data.video_banner_title,
    }

    const [headerRef, headerInView] = useInView({
        triggerOnce: false,
    })

    const animation = headerInView ? "after" : "before"

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <MobileCarousel data={gallery} awards={product_awards_group} />
            <DesktopWrapper ref={headerRef}>
                <DesktopGallery data={gallery} awards={product_awards_group} />
                <ProductInformation
                    lang={langSlug}
                    title={product_title}
                    description={product_description}
                    tag={product_tag}
                    size={product_dimensions}
                    family={product_family}
                    colors={body[1].items}
                    usps={usps}
                    galleryLength={gallery.length}
                    animation={animation}
                />
                <ProductLegal data={product_legal} />
            </DesktopWrapper>
            {/* {product_quotes.length > 1 && <Quotes data={product_quotes} />} */}
            {youtube_id && (
                <VideoBanner
                    lang={langSlug}
                    videoId={youtube_id}
                    title={videoData.title}
                    subtitle={videoData.subtitle}
                />
            )}
            <HowTo
                pruning={pruning}
                growing={growing}
                planting={planting}
                general={howtoData}
                color={product_quotes.length <= 1 && "light"}
            />
            <Banner lang={langSlug} data={bannerData} />
            {related_items && (
                <Related
                    lang={langSlug}
                    type="product"
                    data={related_items}
                    color={product_quotes.length <= 1 && "dark"}
                    title={collection.data.related_title.text}
                    subtitle={collection.data.related_subtitle}
                />
            )}
        </>
    )
}

export const QUERY_PRODUCTITEM = graphql`
    query ProductItem($id: String!, $lang: String!) {
        product: prismicProductItem(id: { eq: $id }) {
            id
            lang
            ...ProductItemQuery
        }
        collection: prismicCollectionPage(lang: { eq: $lang }) {
            id
            lang
            data {
                related_title {
                    text
                }
                related_subtitle
                video_banner_subtitle
                video_banner_title
                howto_title {
                    text
                }
                planting {
                    planting_title {
                        text
                    }
                    planting_description {
                        html
                    }
                    fallback_image {
                        alt
                        fluid(maxWidth: 300) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    youtube_id
                }
                growing {
                    growing_title {
                        text
                    }
                    growing_description {
                        html
                    }
                    fallback_image {
                        alt
                        fluid(maxWidth: 700) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    youtube_id
                }
                pruning {
                    pruning_title {
                        text
                    }
                    pruning_description {
                        html
                    }
                    fallback_image {
                        alt
                        fluid(maxWidth: 300) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    youtube_id
                }
                usp_items {
                    usp {
                        document {
                            ... on PrismicProductUsp {
                                data {
                                    icon
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
                banner_subtitle
                banner_title {
                    text
                }
                banner_description {
                    html
                    text
                }
                banner_button_label
                banner_button_link {
                    slug
                }
            }
        }
    }
`

export default ProductItem
