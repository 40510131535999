import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Award from "./../Award"
import VerticalTitle from "../VerticalTitle"

const List = styled.ul`
    position: relative;

    display: none;

    ${props => props.theme.above.desktop`
        display: inline-block;

        width: 100%;
        max-width: 40%;
    `}
`

const AwardWrapper = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    max-width: 40%;

    height: calc(100% - 16rem);

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const StyledAward = styled(Award)`
    position: sticky;

    top: 0;

    z-index: 1;
    right: 4rem;
    display: flex;
    justify-content: flex-end;
    padding: 0rem 4rem;

    ${props => props.theme.above.desktop`
        img {
            right: 4rem;

            width: 7rem;
        }
    `}
`

const Item = styled.li`
    position: relative;
    height: 100%;
    max-height: 95vh;
    overflow: hidden;
`

const StyledImage = styled(Img)``

const DesktopGallery = ({ data, awards }) => {
    return (
        <>
            {awards.length > 0 && (
                <AwardWrapper>
                    <StyledAward awards={awards} />
                </AwardWrapper>
            )}
            <List>
                {data.map(({ large }, index) => (
                    <Item key={index}>
                        <StyledImage
                            fluid={large.fluid}
                            alt={large.alt}
                            fadeIn={false}
                            loading="eager"
                        />
                        <VerticalTitle title={large.alt} />
                    </Item>
                ))}
            </List>
        </>
    )
}

export default DesktopGallery
