import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Wrapper = styled.div`
    padding: 0 3rem 6rem 3rem;

    overflow: hidden;

    ${props => props.theme.above.desktop`
        position: absolute;

        right: 6rem;
        bottom: 0;

        padding: 0 0 6rem 0;
    `}
`

const Inner = styled(motion.div)`
    display: block;

    width: 100%;

    p {
        font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
        font-size: 1.2rem;
        line-height: 2rem;

        color: ${props => props.theme.colors.darkText}; 
    }

    ${props => props.theme.above.desktop`
        text-align: right;
    `}
`

const ProductLegal = ({
    data,
    className
}) => {

    const [ref, inView] = useInView({
        threshold: 0.25,
        triggerOnce: false
    })

    return (
        <Wrapper ref={ref} className={className}>
            <Inner
                animate={inView ? 'after' : 'before'}
                initial='before'
                variants={{
                    before: {
                        opacity: 0,
                        y: '100%'
                    },
                    after: {
                        opacity: 1,
                        y: '0%'
                    }
                }}
                transition={{
                    duration: 1,
                    damping: 100,
                    stiffness: 100,
                    ease: 'circOut',
                }}
                dangerouslySetInnerHTML={{ __html: data.html }}
            />
            
        </Wrapper>
    )
}

export default ProductLegal
