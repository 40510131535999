import React, { useContext, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import stickybits from "stickybits"

import { generatePath } from "./../../utils/helpers"
import { DictionaryContext } from "./../../contexts/dictionary"

import Line from "../Line"
import { Colors } from "./../Shared"
import Usps from "./Usps"

const Wrapper = styled.div`
    display: block;

    width: 100%;

    ${props => props.theme.above.desktop`
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        top: 50%;

        transform: translateY(-50%);

        width: 60%;
        height: 100%;

        ${
            props.galleryLength > 1
                ? `
            vertical-align: top;
        `
                : `
            position: absolute;
        `
        }        
    `}
`

const Information = styled.div`
    padding: 4rem 3rem;

    ${props => props.theme.above.desktop`
        max-width: 50rem;

        padding: 10rem 0;        
    `}
`

const Top = styled(motion.div)`
    margin-bottom: 1rem;
`

const StyledLine = styled(Line)`
    display: none;

    ${props => props.theme.above.desktop`
        display: inline-block;
        margin-right: 1rem;
    `}
`

const FamilyWrapper = styled(Link)`
    display: inline-block;
    vertical-align: middle;

    position: relative;

    overflow: hidden;

    color: ${props => props.theme.colors.darkText};

    ${props => props.theme.above.desktop`
        height: 2.5rem;

        &:hover {
            .family-inner {
                transform: translateY(-2.5rem);
                color: ${props => props.theme.colors.white}
            }
        }
    `}
`

const FamilyInner = styled.div`
    transition: transform 1s ${props => props.theme.transitions.cubic};

    ${props => props.theme.above.desktop`
        height: 5rem;
    `}
`

const SubtextStyles = css`
    display: block;

    font-family: ${props => props.theme.fontFamily.neueMontrealMedium};
    font-size: 1rem;
    letter-spacing: 0.15rem;

    text-transform: uppercase;

    ${props => props.theme.above.desktop`
        height: 2.5rem;

        font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
        font-size: 1.2rem;
        line-height: 2.5rem;
    `}
`

const Family = styled.span`
    ${SubtextStyles}

    color: ${props => props.theme.colors.darkText};
`

const ViewAll = styled.span`
    ${SubtextStyles}

    display: none;

    color: currentColor;

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const TitleWrapper = styled.div`
    position: relative;

    margin-bottom: 1rem;

    overflow: hidden;
`

const Title = styled(motion.h2)`
    font-size: 2.4rem;
    line-height: 2.4rem;

    ${props => props.theme.above.desktop`
        font-size: 4.5rem;
        line-height: 5.5rem;
    `}
`

const Description = styled(motion.div)`
    margin-bottom: 4rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 2.5rem;
    `}
`

const Info = styled(motion.div)`
    margin-bottom: 2rem;
`

const InfoLabel = styled.h4`
    display: inline-block;
    vertical-align: middle;

    margin-right: 1.5rem;

    font-family: ${props => props.theme.fontFamily.circularMedium};
    font-size: 1.5rem;
    line-height: 1;

    color: ${props => props.theme.colors.white};
`

const InfoValueStyles = css`
    display: inline-block;
    vertical-align: middle;

    position: relative;

    top: 0.1rem;

    font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 0.12rem;

    color: ${props => props.theme.colors.darkText};
`

const InfoValue = styled.span`
    ${InfoValueStyles}
`

const InfoValueLink = styled.a`
    ${InfoValueStyles}

    transition: color 1s ${props => props.theme.transitions.cubic};

    ${props => props.theme.above.desktop`
        &:hover {
            color: ${props => props.theme.colors.white};
        }
    `}
`

const InfoValueClick = styled.button`
    ${InfoValueStyles}

    transition: color 1s ${props => props.theme.transitions.cubic};

    ${props => props.theme.above.desktop`
        &:hover {
            color: ${props => props.theme.colors.white};
        }
    `}
`

const StyledColors = styled(Colors)`
    margin-bottom: 4rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 3rem;
    `}
`

const StyledUsps = styled(Usps)``

const transition = {
    duration: 0.5,
    damping: 100,
    stiffness: 100,
    ease: "circOut",
}

const ProductInformation = ({
    lang,
    galleryLength,
    title,
    description,
    family,
    tag,
    size,
    colors,
    usps,
    animation
}) => {
    const wrapperRef = useRef(null)
    const dictionary = useContext(DictionaryContext)

    useEffect(() => {
        galleryLength > 1 &&
            stickybits(wrapperRef.current, { verticalPosition: "center" })
    }, [])

    const shareHandler = (title, url) => {
        window.navigator
            .share({
                title: title,
                url: url,
            })
            .then(() => {
                this.setState({
                    sharedSuccess: true,
                })
            })
            .catch(error => {
                this.setState({
                    sharedSuccess: false,
                })
            })
    }

    const shareOnWhatsapp = () => {
        if (typeof window !== "undefined" && window.navigator.share) {
            return (
                <InfoValueClick
                    onClick={() =>
                        shareHandler(title.text, window.location.href)
                    }
                >
                    Whatsapp
                </InfoValueClick>
            )
        } else {
            return (
                <InfoValueLink
                    href={`https://web.whatsapp.com/send?text=${
                        title.text
                    }: ${description.text.slice(
                        0,
                        150
                    )}.. - Read more about me: ${typeof window != "undefined" &&
                        window.location.href}`}
                    target="_blank"
                >
                    Whatsapp
                </InfoValueLink>
            )
        }
    }

    return (
        <Wrapper ref={wrapperRef} galleryLength={galleryLength}>
            <Information>
                <Top
                    animate={animation}
                    variants={{
                        before: {
                            opacity: 0,
                            x: -25,
                        },
                        after: {
                            opacity: 1,
                            x: 0,
                        },
                    }}
                    transition={transition}
                >
                    <StyledLine />
                    <FamilyWrapper to={generatePath(lang, "collection")}>
                        <FamilyInner className="family-inner">
                            <Family>{family.document.data.title.text}</Family>
                            <ViewAll>{dictionary.view_all_products}</ViewAll>
                        </FamilyInner>
                    </FamilyWrapper>
                </Top>
                <TitleWrapper>
                    <Title
                        animate={animation}
                        variants={{
                            before: {
                                opacity: 0,
                                y: 100,
                            },
                            after: {
                                opacity: 1,
                                y: 0,
                            },
                        }}
                        transition={transition}
                    >
                        {title.text}
                    </Title>
                </TitleWrapper>
                <Description
                    dangerouslySetInnerHTML={{ __html: description.html }}
                    animate={animation}
                    variants={{
                        before: {
                            opacity: 0,
                            y: -25,
                        },
                        after: {
                            opacity: 1,
                            y: 0,
                        },
                    }}
                    transition={transition}
                />
                <Info
                    animate={animation}
                    variants={{
                        before: {
                            opacity: 0,
                            y: 25,
                        },
                        after: {
                            opacity: 1,
                            y: 0,
                        },
                    }}
                    transition={transition}
                >
                    <InfoLabel>{dictionary.size}</InfoLabel>
                    <InfoValue>{size}</InfoValue>
                </Info>
                <Info
                    animate={animation}
                    variants={{
                        before: {
                            opacity: 0,
                            y: 25,
                        },
                        after: {
                            opacity: 1,
                            y: 0,
                        },
                    }}
                    transition={transition}
                >
                    <InfoLabel>Share</InfoLabel>
                    {shareOnWhatsapp()}
                </Info>
                {colors && <StyledColors data={colors} animation={animation} />}
                <StyledUsps
                    data={usps}
                    animation={animation}
                    variants={{
                        before: {
                            opacity: 0,
                            y: -25,
                        },
                        after: {
                            opacity: 1,
                            y: 0,
                        },
                    }}
                    transition={transition}
                />
            </Information>
        </Wrapper>
    )
}

export default ProductInformation
