import React from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"

import SnowSVG from "./../../graphics/snow.svg"
import GrowSVG from "./../../graphics/grow.svg"
import FloweringSVG from "./../../graphics/flowering.svg"

const List = styled(motion.ul)``

const Item = styled.li`
    margin-bottom: 1rem;

    overflow: hidden;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${props => props.theme.above.desktop`
        margin-bottom: 2rem;
    `}
`

const Inner = styled(motion.div)`
    position: relative;
`

const IconStyles = css`
    display: inline-block;
    vertical-align: middle;

    width: 2rem;

    margin-right: 1.5rem;

    path {
        fill: ${props => props.theme.colors.white};
    }

    ${props => props.theme.above.desktop`
        margin-right: 2rem;

        width: 2.5rem;
    `}
`

const StyledSnowIcon = styled(SnowSVG)`
    ${IconStyles}
`

const StyledFloweringIcon = styled(FloweringSVG)`
    ${IconStyles}

    path {
        stroke: ${props => props.theme.colors.white};
        stroke-width: 0.5;
    }
`

const StyledGrowIcon = styled(GrowSVG)`
    ${IconStyles}
`

const Label = styled.span`
    display: inline-block;
    vertical-align: middle;

    color: ${props => props.theme.colors.white};
`

const Usps = ({ data, className, animation, transition }) => {
    return (
        <List
            className={className}
            animate={animation}
            variants={{
                before: {},
                after: {
                    transition: {
                        staggerChildren: 0.15,
                    },
                },
            }}
        >
            {data.map(({ usp: { document } }, index) => (
                <Item key={index}>
                    <Inner
                        initial={{
                            opacity: 0,
                            skewX: 3,
                            y: 25,
                        }}
                        variants={{
                            before: {
                                opacity: 0,
                                skewX: 3,
                                y: 25,
                            },
                            after: {
                                opacity: 1,
                                skewX: 0,
                                y: 0,
                            },
                        }}
                        transition={transition}
                    >
                        {document.data.icon == "Snow" && <StyledSnowIcon />}
                        {document.data.icon == "Flowering" && (
                            <StyledFloweringIcon />
                        )}
                        {document.data.icon == "Grow" && <StyledGrowIcon />}
                        <Label>{document.data.title.text}</Label>
                    </Inner>
                </Item>
            ))}
        </List>
    )
}

export default Usps
