import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import Award from "../Award"

const Wrapper = styled.div`
    display: block;

    position: relative;

    padding-top: 5rem;

    .alice-carousel__dots {
        position: absolute;

        left: 50%;
        bottom: 3rem;

        transform: translateX(-50%);
    }

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const StyledImage = styled(Img)`
    width: 100%;

    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const StyledAward = styled(Award)`
    position: absolute;

    top: 5rem;
    right: 3rem;

    z-index: 1;
`

const StyledTitle = styled.span`
    position: absolute;

    left: 0;
    bottom: 3.5rem;

    width: 100%;

    padding: 2rem;

    text-align: center;

    font-size: 1rem;

    color: #fff;
`

const MobileCarousel = ({ className, data, awards }) => {
    const params = {
        centeredSlides: true,
        initialSlide: 0,
        loop: true,
        preloadImages: false,
        grabCursor: true,
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
        },
    }

    return (
        <Wrapper className={className}>
            <Swiper {...params}>
                {data.map(({ small }, index) => (
                    <div style={{ position: "relative" }} key={index}>
                        <StyledImage
                            fluid={small.fluid}
                            alt={small.alt}
                            key={index}
                            fadeIn={false}
                            loading="eager"
                        />
                        <StyledTitle>{small.alt}</StyledTitle>
                    </div>
                ))}
            </Swiper>
            {awards && awards.length > 0 && (
                <StyledAward awards={awards} />
            )}
        </Wrapper>
    )
}

export default MobileCarousel
