import React from 'react'
import styled from 'styled-components'

import TitleWithButton from './../../components/TitleWithButton'

const Wrapper = styled.section`
    position: relative;

    background-color: ${props => props.theme.colors.darkLight};
`

const Inner = styled.div`
    width: 100%;

    padding: 6rem 0;

    text-align: center;

    ${props => props.theme.above.desktop`
        max-width: 55rem;

        margin: 0 auto;
        // padding: 12rem 0;
    `}
`

const StyledTitleWithButton = styled(TitleWithButton)`
    text-align: center;

    .line {
        display: none;
    }

    .description {
        max-width: 55rem;

        margin: 0 auto 2rem auto;
    }

    ${props => props.theme.below.desktop`
        .button-circle {
            display: block;
        }
    `}
`

const Banner = ({
    className,
    lang,
    data: {
        subtitle,
        title,
        description,
        buttonLabel,
        buttonLink
    }
}) => {
    return (
        <Wrapper className={className}>
            <Inner>
                <StyledTitleWithButton
                    lang={lang}
                    title={title.text}
                    subtitle={subtitle}
                    description={description.html}
                    button={{
                        type: 'arrow',
                        direction: 'right',
                        label: buttonLabel,
                        to: buttonLink.slug || 'worldwide'
                    }}
                />
            </Inner>
        </Wrapper>
    )
}

export default Banner
